@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  box-sizing: border-box;
  /* font-family: "Playfair Display", serif; */
  font-family: "Roboto Slab", serif;
  scroll-behavior: smooth;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  font-weight: 500;
}

.home-div .homepage-subhead {
  color: #ededed;
}



.owl-theme {
  align-items: center;
}

.iso-card {
  -webkit-box-shadow: 3px 3px 13px 0px rgba(50, 50, 50, 0.25);
  -moz-box-shadow: 3px 3px 13px 0px rgba(50, 50, 50, 0.25);
  box-shadow: 3px 3px 13px 0px rgba(50, 50, 50, 0.25);
  overflow: hidden;
  margin-top: 3vh;

  position: relative;
  display: flex ; 
  flex-direction: column;
  margin-bottom: 2vh;

}

.training-card{
  height: 48vh;
}

.iso-card img {

  height: 25vh;
max-height: 25vh;
  object-fit: cover;
}

.iso-card .btn {
  /* background-color: #b9853f; */
  border: none;
}

.iso-card .card-title,
.card-text {
  font-size: 15px;
  font-weight: bold;
}

.consultancy-card {
  overflow: hidden;
  display: flex;
margin-bottom: 10px;
  overflow: hidden;
  height: 65vh;

}

.consultancy-card .consultancy-img {
  flex: 1;
  height: 25vh;
max-height: 25vh;
  object-fit: contain;

  margin-left: auto;
  margin-right: auto;
}

.card-body {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.myyear {
  transform: translate(-70px, -109px) !important ;
}

.center-icon-box {
  margin-left: 0%;
  transform: translateX(-5%);
  text-align: center;
}



.content-page-button{
  
 margin-bottom: 20px;
 padding: 10px 25px;
 background-color: #b9853f;
 width: max-content;
}


@media only screen and (max-width: 800px) {

  .content-page-button{
    width: 100%;
  }

  .center-icon-box {
    margin-left: 0%;
    transform: translateX(0%);
    text-align: left;
   
  }
  
  .training-card{
    height: 70vh;
  }


  .myyear {
    transform: translate(-16px, -100px) !important ;
    display: block;
    font-size: 29p;
  }

  .iso-card {
    max-height: 75vh;
    height: auto;
  }

  .iso-card img {
    height: auto;
 
  }
  .consultancy-card .consultancy-img {
    max-height: 40vh;
    height: 40vh;
  }

  .iso-card .card-title,
  .card-text {
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iso-card .btn {
    margin-top: 20px;
    font-size: 18px;
  }

  .center-icon-box {
    margin-left: 0%;
    /* transform: translateX(-10%); */
  }
}

.sale-static-box {
  height: 40%;
  width: 80%;
  background-color: brown;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  background-color: transparent !important;
}

.offer-container{
    background-color:white;
    margin-top: 2vh;
    margin-bottom: 5vh;
    text-align: center;
    padding: 8px;
    transition: all 0.3s ease-in;
    /* animation: box-shadow 1s infinite ease-in; */
    cursor: default;
    border: 0.5px solid #0B0C26;
}

.col-container{
    animation:  text-shadow 2s infinite ease;
}

.col-container:hover{
transform: scale(1.05);
transition: transform 0.6s ease-in-out;
}



.offer-container{
    -webkit-box-shadow: 6px 6px 8px 0px rgb(185,133,63,0.17);
    -moz-box-shadow:    6px 6px 8px 0px rgb(185,133,63,0.17);
    box-shadow:         6px 6px 8px 0px rgb(185,133,63,0.17);
}

@keyframes box-shadow{
    0%{
        -webkit-box-shadow: 6px 6px 8px 0px rgb(185,133,63,0.38) ;
        -moz-box-shadow:    6px 6px 8px 0px rgb(185,133,63,0.38) ;
        box-shadow:         6px 6px 8px 0px rgb(185,133,63,0.38) ;
    }

    30%{
        -webkit-box-shadow: 4px 4px 9px 0pxrgb(185,133,63,0.54) ;
-moz-box-shadow:    4px 4px 9px 0px rgb(185,133,63,0.54) ;
box-shadow:         4px 4px 9px 0px rgb(185,133,63,0.54);
}

50%{
    -webkit-box-shadow: 4px 4px 9px 0px rgb(185,133,63,0.64) ;
-moz-box-shadow:    4px 4px 9px 0px rgb(185,133,63,0.64) ;
box-shadow:         4px 4px 9px 0px rgb(185,133,63,0.64) ;
}

100%{

    -webkit-box-shadow: 4px 4px 16px 0px rgb(185,133,63,0.9);
-moz-box-shadow:    4px 4px 16px 0px rgb(185,133,63,0.9);
box-shadow:         4px 4px 16px 0px rgb(185,133,63,0.9);
}

}



@keyframes text-shadow{
    from{
        color  : black ;
    }
    to{
       color :  rgb(185,133,63) ;
    }
}
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap);
html,
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;
  box-sizing: border-box;
  /* font-family: "Playfair Display", serif; */
  font-family: "Roboto Slab", serif;
  scroll-behavior: smooth;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  font-weight: 500;
}

.home-div .homepage-subhead {
  color: #ededed;
}



.owl-theme {
  align-items: center;
}

.iso-card {
  box-shadow: 3px 3px 13px 0px rgba(50, 50, 50, 0.25);
  overflow: hidden;
  margin-top: 3vh;

  position: relative;
  display: flex ; 
  flex-direction: column;
  margin-bottom: 2vh;

}

.training-card{
  height: 48vh;
}

.iso-card img {

  height: 25vh;
max-height: 25vh;
  object-fit: cover;
}

.iso-card .btn {
  /* background-color: #b9853f; */
  border: none;
}

.iso-card .card-title,
.card-text {
  font-size: 15px;
  font-weight: bold;
}

.consultancy-card {
  overflow: hidden;
  display: flex;
margin-bottom: 10px;
  overflow: hidden;
  height: 65vh;

}

.consultancy-card .consultancy-img {
  flex: 1 1;
  height: 25vh;
max-height: 25vh;
  object-fit: contain;

  margin-left: auto;
  margin-right: auto;
}

.card-body {
  flex: 1.5 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.myyear {
  transform: translate(-70px, -109px) !important ;
}

.center-icon-box {
  margin-left: 0%;
  transform: translateX(-5%);
  text-align: center;
}



.content-page-button{
  
 margin-bottom: 20px;
 padding: 10px 25px;
 background-color: #b9853f;
 width: -webkit-max-content;
 width: -moz-max-content;
 width: max-content;
}


@media only screen and (max-width: 800px) {

  .content-page-button{
    width: 100%;
  }

  .center-icon-box {
    margin-left: 0%;
    transform: translateX(0%);
    text-align: left;
   
  }
  
  .training-card{
    height: 70vh;
  }


  .myyear {
    transform: translate(-16px, -100px) !important ;
    display: block;
    font-size: 29p;
  }

  .iso-card {
    max-height: 75vh;
    height: auto;
  }

  .iso-card img {
    height: auto;
 
  }
  .consultancy-card .consultancy-img {
    max-height: 40vh;
    height: 40vh;
  }

  .iso-card .card-title,
  .card-text {
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
  }

  .iso-card .btn {
    margin-top: 20px;
    font-size: 18px;
  }

  .center-icon-box {
    margin-left: 0%;
    /* transform: translateX(-10%); */
  }
}

.sale-static-box {
  height: 40%;
  width: 80%;
  background-color: brown;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  background-color: transparent !important;
}

.homepage-main {
  height: 70vh;
  width: 100vw;
  margin-bottom: 2vh;
}

.itemhome {
  height: 70vh;
  width: 110vw;
}

.owl-item {
  padding: 0 !important;
}

.home-div {
  height: 70vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  margin-bottom: 10vh;
  background-position-x: right;
}

.homeimage1 {
    background-image: url(/static/media/hp1.841089af.png);
  
}

.homeimage2 {
  background-image: url(/static/media/meeting.0c88b75a.png);
}



.homeimage3 {
  background-image: url('https://blog.ringostat.com/en/wp-content/uploads/sites/2/2017/08/data-dashboard-in-office.jpg');
  }


.homeimage4 {
  
  
  
    background-image: url("https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  }
  



  
.home-div h2 {
  margin-top: 32%;
  color: white;
  
}

.cta-button {
  background-color: #b9853f !important;
  padding: 8px 20px;
  margin-top: 3vh;
}

.cta-button:hover {
  background-color: transparent !important;
  border-color: white !important;
}


/* .logo-caoursel-image{
  width: 100px;
  object-fit: contain;
} */


.logo-carousel .item {
   width: 160px; 
   object-fit: contain; 
  overflow: hidden;

  display: flex;
  align-items: center;
}


.logo-carousel .owl-carousel .owl-stage{
  display: flex !important;
  align-items: center !important;
}

.owl-carousel .owl-stage {

  display: flex !important;
  align-items: center !important;
 }


@media only screen and (max-width: 800px) {
  .home-div {
    height: 60vh;
    margin: 0;
    margin-bottom: 10vh;
  }

  .homepage-main , .itemhome  {
    height: 60vh;
    margin-bottom: 5vh;
  }

  .home-div h2 {
 font-size: 24px;
    color: white;
    padding: 5px;
 
  }

  .home-div .col-md-6{
    -webkit-backdrop-filter: brightness(0.5);
            backdrop-filter: brightness(0.5);
  }
  
 

}



@media only screen and (max-width: 800px) {

  .home-div h2 {
    font-size: 22px;
  }

  .homepage-main , .itemhome,.home-div   {
    height: 70vh;
    margin-bottom: 5vh;
  }

  .home-div h2 {
    margin-top: 40%;
  }


  .home-div h5 {
    font-size: 18px;
  }

}
figure.snip1390 {
    font-family: 'Roboto', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    min-width: 280px;
    max-width: 380px;
    width: 100%;
    color: #000000;
    text-align: center;
    font-size: 16px;
    background-color: #b9853f;
    padding: 15px;
    background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
    box-sizing: border-box;
  }
  figure.snip1390 *,
  figure.snip1390 *:before,
  figure.snip1390 *:after {
    box-sizing: border-box;
    transition: all 0.35s ease;
  }
  figure.snip1390 figcaption {
    width: 100%;
  }
  figure.snip1390 h3,
  figure.snip1390 h4,
  figure.snip1390 blockquote {
    margin: 0;
  }
  figure.snip1390 h3,
  figure.snip1390 h4 {
    font-weight: 300;
  }
  figure.snip1390 h3 {
    color: #ffffff;
  }
  figure.snip1390 h4 {
    color: #a6a6a6;
  }

h5{
    color:#6e6e6e;
    font-weight: 300;
}

  figure.snip1390 blockquote {
    font-size: 1em;
    height: 30vh;
    padding: 30px 20px 40px 20px;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
    text-align: left;
    position: relative;
 
  }
  figure.snip1390 blockquote:before {
    font-family: 'FontAwesome';
    /* content: "\201C"; */
    position: absolute;
    font-size: 70px;
    opacity: 0.25;
    font-style: normal;
    top: 0px;
    left: 20px;
  }

blockquote div{
  overflow: hidden;
  margin-bottom: 10px;
}

  figure.snip1390 .profile {
    border-radius: 50%;
  height : 70px ;
  width  : 70px ;
    display: inline-block;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
    border: solid 5px #A6A57A;
    object-fit: cover;
  }
  


  .testimonial-section{
    min-height: 100vh;
      height: 'auto';
      padding : 20px;
  }


.testimonial-card{
  min-height: 50vh;

}

figure{
 
}


.owl-next{
  font-size: 16px;
}
@media only screen and (max-width: 800px) {

  figure.snip1390 blockquote {
    height: 20vh;
  }

  figure.snip1390 {
    min-width: 80vw;
    max-width: 80vw;
  }

  .testimonial-section{
    min-height: 70vh;
      height: 'auto';
  }


}
.offer-container{
    background-color:white;
    margin-top: 2vh;
    margin-bottom: 5vh;
    text-align: center;
    padding: 8px;
    transition: all 0.3s ease-in;
    /* animation: box-shadow 1s infinite ease-in; */
    cursor: default;
    border: 0.5px solid #0B0C26;
}

.col-container{
    animation:  text-shadow 2s infinite ease;
}

.col-container:hover{
transform: scale(1.05);
transition: transform 0.6s ease-in-out;
}



.offer-container{
    box-shadow:         6px 6px 8px 0px rgb(185,133,63,0.17);
}

@keyframes box-shadow{
    0%{
        box-shadow:         6px 6px 8px 0px rgb(185,133,63,0.38) ;
    }

    30%{
box-shadow:         4px 4px 9px 0px rgb(185,133,63,0.54);
}

50%{
box-shadow:         4px 4px 9px 0px rgb(185,133,63,0.64) ;
}

100%{
box-shadow:         4px 4px 16px 0px rgb(185,133,63,0.9);
}

}



@keyframes text-shadow{
    from{
        color  : black ;
    }
    to{
       color :  rgb(185,133,63) ;
    }
}

–––––––––––––––––––––––––––––––––––––––––––––––––– */

.intro {
  background:#b8853e;
  padding: 100px 0;
}



.timeline-container{
  width: 100%;
  max-width: 1200px;

  margin: 0 auto;

}

h1 {
  font-size: 2.3rem;
}


/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */



.timeline ul {
  background: #0b0c26;
  padding: 50px 0;
  width: 100vw
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 50px;
  background: #fff;

}

.timeline ul li::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  background: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
padding-left: 20%;
padding: 20px;

}

.timeline ul li div::before {
  content: '';
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent#b8853e transparent transparent;
}

.timeline ul li:nth-child(even) div {
  left: -439px;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent#b8853e;
}

time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}


/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li::after {
  transition: background .5s ease-in-out;
}

.timeline ul li.in-view::after {
  background:#b8853e;
}

.timeline ul li div {
 

  transition: all .5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(100px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-100px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}


/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 900px) {


  h1 {
    font-size: 1.7rem;
  }
  

  .timeline ul li div {
    width: 250px;
  }
  .timeline ul li:nth-child(even) div {
    left: -289px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 91px);
  }
  .timeline ul li:nth-child(even) div {
    left: 45px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent#b8853e transparent transparent;
  }
}





.top-bar{
  display: flex;
  justify-content: space-between;
  color: white !important;
  align-items: center;

}

#collapsibleNavbar{
  transition: all 0.5s ease-in-out;
}

.top-bar a{
  color: white !important;
}

.top-bar-left{
  background-color: #b9853f;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 40%;
}

.top-bar-right{
  background-color: #0B0C26;
  width: 60%;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.top-bar-right div{
  margin-left: 10px;
  margin-right: 10px;
}

.navbar{
  padding: 0;
 padding: 1vh 5vw;
}

.navbar-brand img{
  max-width: 120px;
}
.bg-light{
  background-color: #fff !important;
}

.nav-link{
  font-weight: 500;
  font-size: 18px;
}


@media only screen and (max-width: 800px) {

  .top-bar{
    display: none;
  }

}
.gallery-title {
  font-size: 36px;
  color: #42b32f;
  text-align: center;
  font-weight: 500;
  margin-bottom: 70px;
}
.gallery-title:after {
  content: "";
  position: absolute;
  width: 7.5%;
  left: 46.5%;
  height: 45px;
  border-bottom: 1px solid #5e5e5e;
}
.filter-button {
  font-size: 18px;
  border: 1px solid #42b32f;
  border-radius: 5px;
  text-align: center;
  color: #42b32f;
  margin-bottom: 30px;
}
.filter-button:hover {
  font-size: 18px;
  border: 1px solid #42b32f;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  background-color: #42b32f;
}
.btn-default:active .filter-button:active {
  background-color: #42b32f;
  color: white;
}

.port-image {
  width: 100%;
}

.gallery_product {
  margin-bottom: 30px;
}

.gallery-container {
  min-height: 100vh;
  transition: all 0.5s ease-in-out;
  background-color: white;
}

.react-tabs__tab--selected {
  background-color: #b9853f;
  color: white;
  padding: 10px 20px;
  border-radius: 2px;
  transition: all 0.5s ease-in-out;
}

.tablist {
  display: flex;
  margin-top: 2vh;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
  list-style: none;
}

.tab {
  margin : 5px;
  /* min-width: 60px; */
  padding: 10px;
  text-align: center;
}

.image-container img {
  object-fit: cover;
  height: 240px;
  max-width: 400px;
overflow: hidden;
  box-shadow: 4px 4px 15px 0px rgba(50, 50, 50, 0.23);
}

.image-container {
  display: flex;
margin: 10px;
  overflow: hidden;
  flex-wrap: wrap;

  transition: all 0.5s ease-in-out;
}

.legalcontainer{
    width: 90%;

  
  }

.legalheading{
    margin-top: 10vh;
    margin-bottom: 10vh;
    
    
    padding: 10px;
    box-shadow:1px 4px 18px 2px #8888
  
    
}
.legalheading h3 {
    color: #b9853f;
}
.legalheading p {
    color: #212121;
}
@media screen and (min-width: 480px) {
    .legalcontainer{
        width: 70%;
       
      
      }
    
    .legalheading{
        margin-top: 5vh;
       
        padding: 8vh;
        
        
    }
    
  }

.upcard-heading-div {
  background-color: #b9853f;
  color: white;
  text-align: center;
  padding: 10px;
  color: white !important ;
}

.upcard-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 5vw;
  padding-right: 5vw;
}

.upcard-fullcard {
  box-shadow: 5px 8px 15px 5px rgba(0, 0, 0, 0.22);
  padding: 0;
  margin: 20px;
margin-top: 10px;
max-width: 22vw;
}

.upcard-subscribe-button {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upcard-button-style:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 800px) {
  .upcard-fullcard {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 90vw;
  }
}


figure.snip1390 {
    font-family: 'Roboto', Arial, sans-serif;
    position: relative;
    overflow: hidden;
    min-width: 280px;
    max-width: 380px;
    width: 100%;
    color: #000000;
    text-align: center;
    font-size: 16px;
    background-color: #b9853f;
    padding: 15px;
    background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  figure.snip1390 *,
  figure.snip1390 *:before,
  figure.snip1390 *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
  }
  figure.snip1390 figcaption {
    width: 100%;
  }
  figure.snip1390 h3,
  figure.snip1390 h4,
  figure.snip1390 blockquote {
    margin: 0;
  }
  figure.snip1390 h3,
  figure.snip1390 h4 {
    font-weight: 300;
  }
  figure.snip1390 h3 {
    color: #ffffff;
  }
  figure.snip1390 h4 {
    color: #a6a6a6;
  }

h5{
    color:#6e6e6e;
    font-weight: 300;
}

  figure.snip1390 blockquote {
    font-size: 1em;
    height: 30vh;
    padding: 30px 20px 40px 20px;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
    text-align: left;
    position: relative;
 
  }
  figure.snip1390 blockquote:before {
    font-family: 'FontAwesome';
    /* content: "\201C"; */
    position: absolute;
    font-size: 70px;
    opacity: 0.25;
    font-style: normal;
    top: 0px;
    left: 20px;
  }

blockquote div{
  overflow: hidden;
  margin-bottom: 10px;
}

  figure.snip1390 .profile {
    border-radius: 50%;
  height : 70px ;
  width  : 70px ;
    display: inline-block;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
    border: solid 5px #A6A57A;
    object-fit: cover;
  }
  


  .testimonial-section{
    min-height: 100vh;
      height: 'auto';
      padding : 20px;
  }


.testimonial-card{
  min-height: 50vh;

}

figure{
 
}


.owl-next{
  font-size: 16px;
}
@media only screen and (max-width: 800px) {

  figure.snip1390 blockquote {
    height: 20vh;
  }

  figure.snip1390 {
    min-width: 80vw;
    max-width: 80vw;
  }

  .testimonial-section{
    min-height: 70vh;
      height: 'auto';
  }


}
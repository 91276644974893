.gallery-title {
  font-size: 36px;
  color: #42b32f;
  text-align: center;
  font-weight: 500;
  margin-bottom: 70px;
}
.gallery-title:after {
  content: "";
  position: absolute;
  width: 7.5%;
  left: 46.5%;
  height: 45px;
  border-bottom: 1px solid #5e5e5e;
}
.filter-button {
  font-size: 18px;
  border: 1px solid #42b32f;
  border-radius: 5px;
  text-align: center;
  color: #42b32f;
  margin-bottom: 30px;
}
.filter-button:hover {
  font-size: 18px;
  border: 1px solid #42b32f;
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  background-color: #42b32f;
}
.btn-default:active .filter-button:active {
  background-color: #42b32f;
  color: white;
}

.port-image {
  width: 100%;
}

.gallery_product {
  margin-bottom: 30px;
}

.gallery-container {
  min-height: 100vh;
  transition: all 0.5s ease-in-out;
  background-color: white;
}

.react-tabs__tab--selected {
  background-color: #b9853f;
  color: white;
  padding: 10px 20px;
  border-radius: 2px;
  transition: all 0.5s ease-in-out;
}

.tablist {
  display: flex;
  margin-top: 2vh;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
  list-style: none;
}

.tab {
  margin : 5px;
  /* min-width: 60px; */
  padding: 10px;
  text-align: center;
}

.image-container img {
  object-fit: cover;
  height: 240px;
  max-width: 400px;
overflow: hidden;
  -webkit-box-shadow: 4px 4px 15px 0px rgba(50, 50, 50, 0.23);
  -moz-box-shadow: 4px 4px 15px 0px rgba(50, 50, 50, 0.23);
  box-shadow: 4px 4px 15px 0px rgba(50, 50, 50, 0.23);
}

.image-container {
  display: flex;
margin: 10px;
  overflow: hidden;
  flex-wrap: wrap;

  transition: all 0.5s ease-in-out;
}

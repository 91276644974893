.upcard-heading-div {
  background-color: #b9853f;
  color: white;
  text-align: center;
  padding: 10px;
  color: white !important ;
}

.upcard-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 5vw;
  padding-right: 5vw;
}

.upcard-fullcard {
  -webkit-box-shadow: 4px 8px 15px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 5px 8px 15px 5px rgba(0, 0, 0, 0.22);
  padding: 0;
  margin: 20px;
margin-top: 10px;
max-width: 22vw;
}

.upcard-subscribe-button {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upcard-button-style:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 800px) {
  .upcard-fullcard {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 90vw;
  }
}

.legalcontainer{
    width: 90%;

  
  }

.legalheading{
    margin-top: 10vh;
    margin-bottom: 10vh;
    
    
    padding: 10px;
    box-shadow:1px 4px 18px 2px #8888
  
    
}
.legalheading h3 {
    color: #b9853f;
}
.legalheading p {
    color: #212121;
}
@media screen and (min-width: 480px) {
    .legalcontainer{
        width: 70%;
       
      
      }
    
    .legalheading{
        margin-top: 5vh;
       
        padding: 8vh;
        
        
    }
    
  }

.homepage-main {
  height: 70vh;
  width: 100vw;
  margin-bottom: 2vh;
}

.itemhome {
  height: 70vh;
  width: 110vw;
}

.owl-item {
  padding: 0 !important;
}

.home-div {
  height: 70vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  margin-bottom: 10vh;
  background-position-x: right;
}

.homeimage1 {
    background-image: url("../../assets/hp1.png");
  
}

.homeimage2 {
  background-image: url('../../assets/meeting.png');
}



.homeimage3 {
  background-image: url('https://blog.ringostat.com/en/wp-content/uploads/sites/2/2017/08/data-dashboard-in-office.jpg');
  }


.homeimage4 {
  
  
  
    background-image: url("https://images.pexels.com/photos/5668473/pexels-photo-5668473.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  }
  



  
.home-div h2 {
  margin-top: 32%;
  color: white;
  
}

.cta-button {
  background-color: #b9853f !important;
  padding: 8px 20px;
  margin-top: 3vh;
}

.cta-button:hover {
  background-color: transparent !important;
  border-color: white !important;
}


/* .logo-caoursel-image{
  width: 100px;
  object-fit: contain;
} */


.logo-carousel .item {
   width: 160px; 
   object-fit: contain; 
  overflow: hidden;

  display: flex;
  align-items: center;
}


.logo-carousel .owl-carousel .owl-stage{
  display: flex !important;
  align-items: center !important;
}

.owl-carousel .owl-stage {

  display: flex !important;
  align-items: center !important;
 }


@media only screen and (max-width: 800px) {
  .home-div {
    height: 60vh;
    margin: 0;
    margin-bottom: 10vh;
  }

  .homepage-main , .itemhome  {
    height: 60vh;
    margin-bottom: 5vh;
  }

  .home-div h2 {
 font-size: 24px;
    color: white;
    padding: 5px;
 
  }

  .home-div .col-md-6{
    backdrop-filter: brightness(0.5);
  }
  
 

}



@media only screen and (max-width: 800px) {

  .home-div h2 {
    font-size: 22px;
  }

  .homepage-main , .itemhome,.home-div   {
    height: 70vh;
    margin-bottom: 5vh;
  }

  .home-div h2 {
    margin-top: 40%;
  }


  .home-div h5 {
    font-size: 18px;
  }

}
.top-bar{
  display: flex;
  justify-content: space-between;
  color: white !important;
  align-items: center;

}

#collapsibleNavbar{
  transition: all 0.5s ease-in-out;
}

.top-bar a{
  color: white !important;
}

.top-bar-left{
  background-color: #b9853f;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 40%;
}

.top-bar-right{
  background-color: #0B0C26;
  width: 60%;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.top-bar-right div{
  margin-left: 10px;
  margin-right: 10px;
}

.navbar{
  padding: 0;
 padding: 1vh 5vw;
}

.navbar-brand img{
  max-width: 120px;
}
.bg-light{
  background-color: #fff !important;
}

.nav-link{
  font-weight: 500;
  font-size: 18px;
}


@media only screen and (max-width: 800px) {

  .top-bar{
    display: none;
  }

}